<template>
  <div class="mod-group">
    <div class="cell-header">
      <h4>购买保险记录</h4>
    </div>
    <div class="cell-body">
      <slot></slot>
      <a-table
        rowKey="id"
        :data-source="records"
        :columns="columns"
        :pagination="false"
        bordered
      >
        <span slot="index" slot-scope="text, record, index">
        {{ index + 1 }}
        </span>
        <span slot="ticketNo" slot-scope="text, record">
          {{ `${record.issCode}-${record.ticketNo}` }}
        </span>
        <span slot="segment" slot-scope="text, record">
          {{ `${record.depCode}-${record.arrCode}` }}
        </span>
        <span slot="serviceType" slot-scope="text">
          {{ text | serviceTypeFilter }}
        </span>
        <span slot="orderStatus" slot-scope="text, record">
          <span>{{ text | insuranceDetailStatusFilter }}</span>
              <!-- { value: "RB", label: "退保失败" } -->
          <span :style="{color: 'red'}">{{ text === 'RB' ? `(${record.refundErrorMsg})` : '' }}</span>
              <!-- { value: "SB", label: "投保失败" }, -->
          <span :style="{color: 'red'}">{{ text === 'SB' ? `(${record.issueErrorMsg})` : ''}}</span>
        </span>
        <span slot="action" slot-scope="text, record" >
          <a-button type="link" :disabled="record.orderStatus !== 'PF'" @click="onCancel(record)">退保</a-button>
        </span>
      </a-table>
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import { format } from '@/filters/time'
import { serviceTypeFilter, insuranceDetailStatusFilter } from '@/filters/insurance'

import { insuranceOrderRefund } from '@/api/insurance'

import _ from 'lodash'

export default {
  name: 'InsuranceDetail',
  data () {
    return {
      records: [],
      columns: [
        {
          title: '序号',
          dataIndex: 'id',
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '旅客姓名',
          dataIndex: 'passengerName'
        },
        {
          title: '证件号码',
          dataIndex: 'certificateNo'
        },
        {
          title: '票号',
          dataIndex: 'ticketNo',
          scopedSlots: { customRender: 'ticketNo' }
        },
        {
          title: '航段',
          dataIndex: 'segment',
          scopedSlots: { customRender: 'segment' }
        },
        {
          title: '产品类型',
          dataIndex: 'serviceType',
          scopedSlots: { customRender: 'serviceType' }
        },
        {
          title: '产品名称',
          dataIndex: 'productName'
        },
        {
          title: '产品代码',
          dataIndex: 'productCode'
        },
        {
          title: '金额',
          dataIndex: 'netFare'
        },
        {
          title: '保单号',
          dataIndex: 'insuranceNo'
        },
        {
          title: '购保状态',
          dataIndex: 'orderStatus',
          scopedSlots: { customRender: 'orderStatus' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  props: {
    detail: Object,
    list: Array
  },
  watch: {
    list: function (val) {
      const list = _.cloneDeep(val)
      this.updateRecord(list)
    }
  },
  filters: {
    formatTime: format,
    serviceTypeFilter,
    insuranceDetailStatusFilter
  },
  mounted () {
    const list = _.cloneDeep(this.list)
    this.updateRecord(list)
  },
  methods: {
    onCancel (record) {
      this.$confirm({
        content: `确认旅客 ${record.passengerName} ${record.depCityCn}-${record.arrCityCn} 退掉保险？请您核实旅客信息确认无误后继续操作`,
        okText: '确认',
        cancelText: '取消',
        onOk: async () => {
          try {
            await insuranceOrderRefund({
              detailDTOList: [
                {
                  detailId: record.id,
                  orderId: record.orderId,
                  orderItemId: record.orderItemId,
                  orderItemServiceId: record.serviceId
                }
              ],
              orderId: this.detail.orderId
            })

            this.$message.success('退保成功')
            this.$emit('refund')
          } catch (err) {
            this.$message.error(err.message)
          }
        }
      })
    },
    updateRecord (orderItems) {
      const records = []

      orderItems.forEach((item) => {
        item.orderItemServices.forEach((service) => {
          service.serviceDetails.forEach((detail) => {
            if (detail.serviceType === 'INSURANCE') {
              records.push(detail)
            }
          })
        })
      })

      this.records = records
    }
  }
}
</script>
