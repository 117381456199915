s<template>
  <div class="page-b2b-order-detail insurance-detail-container">
    <div class="loading" v-if="loading">
      <a-spin size="large"></a-spin>
    </div>
    <template v-else>
      <div class="mod-group">
        <div class="cell-header">
          <h4>保险订单基本信息</h4>
        </div>
        <div class="cell-body">
          <table class="table-infomation">
            <tr>
              <th>提交人:</th>
              <td>{{ data.submitter }}</td>
              <th>提交人账户:</th>
              <td>{{ data.submitAccount }}</td>
              <th>联系电话:</th>
              <td>{{ data.contact.contactMobile }}</td>
            </tr>
            <tr>
              <th>机票订单编号:</th>
              <td>{{ data.outOrderNo }}</td>
              <th>保单提交时间:</th>
              <td>
                <span>{{ data.createTime }}</span><br/>
                <span v-if="data.status === 'WP'" :style="{color: 'red'}">(请在30分钟内完成支付，超时将自动取消)</span>
              </td>
              <th>保险订单号:</th>
              <td>{{ data.orderNo }}</td>
            </tr>
            <tr>
              <th>保险订单状态:</th>
              <td>{{ data.status | insuranceStatusFilter }}</td>
            </tr>
          </table>
        </div>
      </div>
      <detail-insurance-record
        v-if="data.orderItems && data.orderItems.length"
        :detail="data"
        :list="data.orderItems"
        @refund="onRefund"
      >
      </detail-insurance-record>
      <detail-insurance-payment
        v-if="data.payments && data.payments.length"
        :list="data.payments"
      >
      </detail-insurance-payment>
      <div class="footer-action">
          <a-button type="primary" size="large" @click="onPay">支付</a-button>
          <a-button :disabled="data.status === 'XX'" type="default" size="large" @click="onCancel">取消订单</a-button>
      </div>
      <a-modal
      :class="['page-b2b-order-detail-model', type]"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :width="600"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="cell-header" slot="title">
        {{ '保险订单支付' }}
        <span>订单号: {{ data.orderNo }}</span>
        <span>订单状态: {{ data.status | insuranceStatusFilter }}</span>
      </div>
      <div class="cell-body">
        <detail-insurance-pay ref="insurancePay" :orderNo="data.orderNo" :status="data.status" :payInfo="payInfo"></detail-insurance-pay>
      </div>
    </a-modal>
    </template>
  </div>
</template>

<script>
import queryString from 'query-string'
import { insuranceOrderDetail, insuranceOrderCancel } from '@/api/insurance'
import DetailInsuranceRecord from './components/detail-insurance-record'
import DetailInsurancePayment from './components/detail-insurance-payment'
import DetailInsurancePay from './components/detail-insurance-pay'
import { format } from '@/filters/time'
import { insuranceStatusFilter } from '@/filters/insurance'
import './css/detail.less'

export default {
  components: {
    DetailInsuranceRecord,
    DetailInsurancePayment,
    DetailInsurancePay
  },
  data () {
    return {
      data: {},
      type: 'insurancePay',
      confirmLoading: false,
      visible: false,
      loading: false,
      payInfo: {},
      orderNo: '',
      userId: ''
    }
  },
  created () {
    const params = queryString.parse(location.search)
    this.orderNo = params.id
    this.userId = params.userId
    this.fetchDetail()
  },
  computed: {},
  methods: {
    async fetchDetail () {
      this.loading = true

      try {
        const data = await insuranceOrderDetail({
          orderNo: this.orderNo,
          userId: this.userId
        })

        this.data = data

        this.loading = false
      } catch (err) {
        this.$message.error(err.message)
      }
    },
    onPay () {
      this.type = 'insurancePay'
      this.payInfo = {
        orderAmount: this.data.orderAmount
      }
      this.visible = true
    },
    async handleOk () {
      const { type } = this

      try {
        this.confirmLoading = true
        await this.$refs[type].submit()
        this.confirmLoading = false
      } catch (err) {
        this.confirmLoading = false
      }
    },
    onCancel () {
      this.$confirm({
        content: '确认取消保险订单吗？',
        okText: '确认',
        cancelText: '取消',
        onOk: async () => {
          await this.reqCancelOrder()
        }
      })
    },
    async reqCancelOrder () {
      try {
        await insuranceOrderCancel({
          orderNo: this.orderNo
        })
        this.$message.success('保险订单取消成功')
        this.fetchDetail()

        this.loading = false
      } catch (err) {
        this.$message.error(err.message)
      }
    },
    onRefund () {
      this.fetchDetail()
    },
    handleCancel () {
      this.visible = false
      this.type = ''
    }
  },
  filters: {
    format,
    insuranceStatusFilter
  }
}
</script>

<style lang="less" scoped>
.footer-action {
  text-align: center;

  .ant-btn {
    & + .ant-btn {
      margin-left: 16px;
    }
  }
}
</style>
