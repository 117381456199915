<template>
  <div class="detail-ticket-pay-container cell-inner">
    <a-descriptions title="支付信息">
      <a-descriptions-item label="订单状态">
        {{ status | insuranceStatusFilter }}
      </a-descriptions-item>
      <a-descriptions-item label="订单总金额">
        ￥{{ payDetail.orderAmount }}
      </a-descriptions-item>
  </a-descriptions>
    <a-tabs class="ticket-tabs" default-active-key="1">
      <a-tab-pane key="1" tab="第三方支付">
        <div class="ticket-pay-list">
          <div
            class="ticket-pay"
            v-for="method in payMethods"
            :key="method.id"
            :class="{ active: checkPayMethod.id === method.id }"
            @click="onMethodClick(method)"
          >
            {{ method.title }}
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>
    <div :style="{color: 'red', marginBottom: '10px'}">
      操作提醒：<br/>
      在快钱页面支付成功后，请点击按钮 “返回商家” 以确保团队网系统及时接收快钱支付通知，请勿直接关闭快钱页面，直接关闭快钱页面将导致订单支付状态更新延迟。
    </div>
    <div class="inner-footer">
      <a-button type="primary" :loading="loading" @click="onSubmitPay">支付</a-button>
    </div>
  </div>
</template>

<script>
import { orderPaySubmit } from '@/api/order'
import { insuranceStatusFilter } from '@/filters/insurance'

export default {
  data () {
    return {
      payDetail: {},
      payMethods: [
        {
          id: 'NNBILL_UP',
          title: '快钱网银',
          type: 'NNBILL',
          mode: 'UP'
        }
        // {
        //   id: 'NNBILL_AC',
        //   title: '快钱账号余额',
        //   type: 'NNBILL',
        //   mode: 'AC'
        // }
      ],
      checkPayMethod: {},
      loading: false
    }
  },
  props: {
    orderNo: String,
    status: String,
    payInfo: Object
  },
  mounted () {
    this.payDetail = this.payInfo
  },
  methods: {
    onMethodClick (method) {
      this.checkPayMethod = method
    },
    async onSubmitPay () {
      if (!this.checkPayMethod.type) {
        this.$message.error('请选择支付方式 ')
        return
      }
      this.loading = true

      try {
        const params = {
          action: 'TEAM_INS',
          amount: this.payDetail.orderAmount.toFixed(2),
          bankId: '',
          currency: 'CNY',
          numOfPeriods: '',
          orderNo: this.orderNo,
          payMethod: 'NORMAL',
          payType: this.checkPayMethod.type,
          payMode: this.checkPayMethod.mode,
          payToReturnUrl: window.location.href,
          terminal: 'PC'
        }
        const result = await orderPaySubmit(params)
        this.loading = false

        if (result.payUrl) {
          window.open(result.payUrl)
        }

        this.$confirm({
          content: '确认是否支付？',
          okText: '我已支付成功',
          onOk: () => {
            location.reload()
          },
          cancelText: '重新继续支付',
          onCancel: () => {}
        })
      } catch (e) {
        this.loading = false

        this.$message.error(e.message)
      }
    }
  },
  filters: {
    insuranceStatusFilter
  }
}
</script>

<style lang="less" scoped>
.detail-ticket-pay-container {
  .base-info {
    margin-bottom: 10px;
  }

  .pay-tabs {
  }
  .ticket-pay-list {
    padding: 20px 0;
  }
  .ticket-pay {
    display: inline-block;
    border: 1px solid @joy-darkblue-color;
    padding: 10px;
    cursor: pointer;

    &.active {
      border: 1px solid @joy-orange-color;
    }
    + .ticket-pay {
      margin-left: 10px;
    }
  }
}
.ant-modal-footer {
  display: none;
}
</style>
